import React from "react";
import { Routes, Route, Link, useMatch, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import "./App.css";

import Categories from "./components/Categories";
import CategoryCard from "./components/CategoryCard";
import Airway from "./components/categories/Airway";
import FaceMask from "./components/categories/airway/FaceMask";
import Hudson from "./components/details/Hudson";

function ButtonAppBar() {
  const navigate = useNavigate();

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              navigate("/");
            }}
          >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            WasteStream
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ButtonAppBar />
      </header>
      <div className="content">
        <Routes>
          <Route path="/" element={<Categories />}></Route>
          <Route path="/airway" element={<Airway />}></Route>
          <Route path="/airway/face-mask" element={<FaceMask />}></Route>
          <Route path="/airway/face-mask/hudson" element={<Hudson />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
