import React from "react";
import { Routes, Route, Link, useMatch } from "react-router-dom";

import Grid from "@mui/material/Grid";
import CategoryCard from "./CategoryCard";
import Airway from "./categories/Airway";

const cards = [
  { title: "Airway" },
  { title: "IV access" },
  { title: "Infusion / collection" },
  { title: "Drugs" },
  { title: "Regional Anaesthesia" },
  { title: "Infection control" },
  { title: "Suturing" },
  { title: "Dressings" },
  { title: "Monitoring" },
  { title: "Packaging" },
];

function Categories() {
  return (
    <Grid container spacing={2}>
      {cards.map((card) => (
        <CategoryCard title={card.title} />
      ))}
    </Grid>
  );
}

export default Categories;
