import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 2608,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 2400,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 2808,
    amt: 2000,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 2750,
    amt: 2100,
  },
];

function Details(props: {
  imageUrl: string;
  title: string;
  materials: string;
  wasteClass: string;
  notes: string;
  co2Saving: string;
  costSaving: string;
  strikeRate: string;
}) {
  return (
    <div className="details">
      <img className="item" src={props.imageUrl}></img>
      <h1>{props.title}</h1>
      <h3>
        <b>{props.wasteClass}</b>
      </h3>
      <p>
        Materials: <b>{props.materials}</b>
      </p>
      <p>{props.notes}</p>
      <p>
        CO<sub>2</sub> saving: <b>{props.co2Saving}g</b>
      </p>
      <p>
        Cost saving: <b>${props.costSaving}</b>
      </p>
      <p>
        Handled correctly: <b>{props.strikeRate}%</b>
      </p>
      <p>
        Your hospital trend (since Dec 2020):
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <LineChart data={data} margin={{ top: 32, left: 8, right: 8 }}>
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </p>
    </div>
  );
}

export default Details;
