import Details from "../Details";
function Hudson() {
  const props = {
    imageUrl: "/items/hudson.jpg",
    title: "Hudson",
    materials: "PVC, elastic",
    wasteClass: "Recycle (co-mingled)",
    notes:
      "If this item is uncontaminated with blood it can be categorised as recycleable PVC.",
    co2Saving: "0.023",
    costSaving: "0.25",
    strikeRate: "20.3",
  };
  return <Details {...props} />;
}

export default Hudson;
