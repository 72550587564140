import React from "react";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CardActionArea } from "@mui/material";
import { kebabCase } from "lodash";

function CategoryCard(props: { title: string }) {
  return (
    <Grid item xs={6}>
      <Link to={kebabCase(props.title)}>
        <Paper
          sx={{
            height: "13vh",
            backgroundColor: "#e2e3e3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" color="text.secondary" gutterBottom>
            {props.title}
          </Typography>
        </Paper>
      </Link>
    </Grid>
  );
}

export default CategoryCard;
