import React from "react";

import Grid from "@mui/material/Grid";
import CategoryCard from "../../CategoryCard";

const cards = [
  { title: "Hudson" },
  { title: "Face Mask" },
  { title: "Clausten Hook" },
];

function FaceMask() {
  return (
    <Grid container spacing={2}>
      {cards.map((card) => (
        <CategoryCard title={card.title} />
      ))}
    </Grid>
  );
}

export default FaceMask;
