import React from "react";
import { Routes, Route, Link, useMatch } from "react-router-dom";

import Grid from "@mui/material/Grid";
import CategoryCard from "../CategoryCard";

const cards = [
  { title: "Face mask" },
  { title: "Oral airway" },
  { title: "Airway Adjuncts" },
  { title: "Laryngoscope blade" },
  { title: "Awake Fibreoptic" },
  { title: "Breathing Circuit" },
  { title: "Suction" },
  { title: "Nasogastric" },
];

function Airway() {
  return (
    <Grid container spacing={2}>
      {cards.map((card) => (
        <CategoryCard title={card.title} />
      ))}
    </Grid>
  );
}

export default Airway;
